<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="requisition-add"
  >
    <div>
      <h2 class="mg-y-2">
        <span
          class="cr-pointer"
          @click="$router.push(`/certification-requisition`)"
        >
          Certification Requisition
        </span>
        >
        <span class="color-light-blue">Add Requisition</span>
      </h2>
      <el-steps
        :active="stepNow"
        align-center
        class="custom-step"
        finish-status="success"
      >
        <el-step>
          <template slot="title">
            <p>Basic Information of Client</p>
            <p>(ข้อมูลบริษัท)</p>
          </template>
        </el-step>
        <el-step>
          <template slot="title">
            <p>Certificate Schema</p>
            <p>(ข้อมูลที่ขอการรับรอง)</p>
          </template>
        </el-step>
        <el-step>
          <template slot="title">
            <p>Additional Documents</p>
            <p>(เอกสารเพิ่มเติม)</p>
          </template>
        </el-step>
        <el-step>
          <template slot="title">
            <p>Check Information</p>
            <p>(ตรวจสอบข้อมูล)</p>
          </template>
        </el-step>
      </el-steps>
      <el-form
        label-position="top"
        :model="form"
        ref="ruleForm"
        :rules="rules"
        status-icon
      >
        <Step1
          v-if="stepNow + 1 == 1"
          :form="form"
          :address="addressAll"
          :companyAll="companyAll"
          :errorAddress="errorAddress"
          :errorCompany="errorCompany"
        />
        <Step2
          :type="'add'"
          v-else-if="stepNow + 1 == 2"
          :form="form"
          @addCondition="addCondition"
          @delCondition="delCondition"
          @checkError="checkboxChangeError"
        />
        <Step3 v-else-if="stepNow + 1 == 3" :form="form" />
        <Step4
          :type="'view'"
          :haveEdit="true"
          v-else-if="stepNow + 1 == 4"
          :form="form"
          @editStep="editStep"
          :rules="rules"
        />
      </el-form>
      <div class="font-error mg-t-2" v-if="checkError">กรุณาเลือก ประเภท</div>
      <div :gutter="15" class="is-flex ai-center js-between mg-t-4">
        <div>
          <el-button
            round
            @click="backStep()"
            v-if="stepNow > 0 && stepNow != endStep - 1"
            >Cancel</el-button
          >
        </div>

        <el-button
          type="warning"
          class="button-radius font-14"
          @click="nextStep()"
          :loading="loadingNext"
          v-if="stepNow != endStep - 1"
          >Next</el-button
        >
        <el-button
          type="warning"
          class="button-radius font-14"
          @click="submitAdd()"
          :loading="loadingNext"
          v-else
          >Save</el-button
        >
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import Step1 from "@/components/requisition/add/Step1";
import Step2 from "@/components/requisition/add/Step2";
import Step3 from "@/components/requisition/add/Step3";
import Step4 from "@/components/requisition/add/Step4";

import { mapState } from "vuex";

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      setCsrf: (state) => state.setCsrf,
    }),
  },
  mounted() {
    this.getCompanyAll();
    this.getAddressAll();
  },
  data() {
    var validateAddress = (rule, value, callback) => {
      if (value === "") {
        this.loadingNext = false;
        return callback(new Error("กรุณากรอก Address Of Client"));
      } else {
        let debounce = null;
        let callbackData = this.checkRepeat(value, "address");
        clearTimeout(debounce);
        debounce = setTimeout(async () => {
          if ((await callbackData) != 1) {
            this.loadingNext = false;
            this.errorAddress = false;
            callback();
          } else {
            this.loadingNext = false;
            this.errorAddress = true;
            callback();
          }
        }, 1000);
      }
    };
    var validateCompany = (rule, value, callback) => {
      if (value === "") {
        this.loadingNext = false;
        return callback(new Error("กรุณากรอก Company name"));
      } else {
        let debounce = null;
        let callbackData = this.checkRepeat(value, "company");
        clearTimeout(debounce);
        debounce = setTimeout(async () => {
          if ((await callbackData) != 1) {
            this.loadingNext = false;
            this.errorCompany = false;
            callback();
          } else {
            this.loadingNext = false;
            this.errorCompany = true;
            callback();
          }
        }, 1000);
      }
    };
    return {
      errorAddress: false,
      errorCompany: false,
      loading: false,
      loadingNext: false,
      stepNow: 0, // step + 1
      endStep: 4,
      form: {
        companyName: "",
        juristicId: "",
        address: "",
        contactPerson: "",
        phone: "",
        email: "",
        conditionsFood: [],
        conditionsISO: [],
        checkboxFood: false,
        checkboxISO: false,
        files: [],
        fileList: [],
        note: "",
      },
      checkError: false,
      rules: {
        companyName: [
          { required: true, validator: validateCompany, trigger: "blur" },
        ],
        address: [
          { required: true, validator: validateAddress, trigger: "blur" },
        ],
        contactPerson: [
          {
            required: true,
            message: "กรุณากรอก Contact person name",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "กรุณากรอก Phone number",
            trigger: "blur",
          },
        ],
        files: [
          {
            type: "array",
            required: true,
            message: "กรุณา Upload File",
            trigger: "change",
          },
        ],
      },
      addressAll: [],
      companyAll: [],
    };
  },
  methods: {
    backStep() {
      this.stepNow -= 1;
      this.form.fileList = [];
    },
    nextStep() {
      this.loadingNext = true;
      if (this.stepNow == 2) {
        this.form.fileList = [];
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (
          this.stepNow == 1 &&
          this.form.checkboxFood == false &&
          this.form.checkboxISO == false
        ) {
          this.checkError = true;
          this.loadingNext = false;
        } else if (valid) {
          if (this.stepNow != this.endStep) {
            this.stepNow += 1;
          } 
          this.loadingNext = false;
        } else {
          this.loadingNext = false;
        }
      });
    },
    checkRepeat(value, type) {
      let result = null;
      if (type == "address") {
        result = this.addressAll.find((row) => row.value == value);
      } else if (type == "company") {
        result = this.companyAll.find((row) => row.value == value);
      }

      if (result != undefined) {
        result = 1;
      } else {
        result = 0;
      }
      return result;
    },

    getCompanyAll() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`certification/company`)
        .then((res) => {
          if (res.data.success) {
            this.companyAll = res.data.obj;
            if (this.companyAll.length > 0) {
              this.companyAll = this.companyAll.reduce((result, row) => {
                let obj = {
                  label: row.value.replaceAll(" ", "&nbsp;"),
                  value: row.value,
                };
                result.push(obj);
                return result;
              }, []);
            }
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {});
    },
    getAddressAll() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`certification/address`)
        .then((res) => {
          if (res.data.success) {
            this.addressAll = res.data.obj;
            if (this.addressAll.length > 0) {
              this.addressAll = this.addressAll.reduce((result, row) => {
                let obj = {
                  label: row.value.replaceAll(" ", "&nbsp;"),
                  value: row.value,
                };
                result.push(obj);
                return result;
              }, []);
            }
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {});
    },
    addCondition(type) {
      if (type == "foodSafety") {
        this.form.conditionsFood.push({
          company: "",
          type: 1,
          standard: "",
          other: "",
          accrecditBy: "",
          catNo: [],
          auditCycle: "",
          valCatFoodSafety: "",
        });
      } else if (type == "iso") {
        this.form.conditionsISO.push({
          company: "",
          type: 2,
          standard: "",
          other: "",
          accrecditBy: "",
          catNo: [],
          auditCycle: "",
          valCatISO: "",
        });
      }
    },
    delCondition(type, index) {
      if (type == "foodSafety") {
        this.form.conditionsFood.splice(index, 1);
      } else if (type == "iso") {
        this.form.conditionsISO.splice(index, 1);
      }
    },
    async submitAdd() {
      // this.loadingNext = true;
      let companyType = [];

      if (this.form.conditionsFood.length > 0) {
        this.form.conditionsFood.forEach((row, index) => {
          let setArr = {
            _id: row._id,
            type: row.type,
            company: row.company,
            standard: row.standard,
            other: row.other,
            accrecditBy: row.accrecditBy,
            scoped: row.scoped,
            catNo: row.catNo,
            auditCycle: row.auditCycle,
          };

          companyType.push(setArr);
        });
      }
      if (this.form.conditionsISO.length > 0) {
        this.form.conditionsISO.forEach((row, index) => {
          let setArr = {
            _id: row._id,
            type: row.type,
            company: row.company,
            standard: row.standard,
            other: row.other,
            accrecditBy: row.accrecditBy,
            scoped: row.scoped,
            catNo: row.catNo,
            auditCycle: row.auditCycle,
          };

          companyType.push(setArr);
        });
      }

      let obj = {
        companyName: this.form.companyName,
        juristicId: this.form.juristicId,
        address: this.form.address,
        contactPerson: this.form.contactPerson,
        phone: this.form.phone,
        email: this.form.email,
        note: this.form.note,
        companyType: await companyType,
      };

      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`certification/create/requisition`, obj)
        .then(async (res) => {
          if (res.data.success) {
            if (this.form.files.length > 0) {
              await this.uploadFiles(res.data.obj);
            }
            this.$notify({
              title: "เพิ่มคำขอสำเร็จแล้ว!",
              type: "success",
              customClass: "success",
            });
            this.$router.push(`/certification-requisition`);
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingNext = false;
        });
    },
    editStep(step) {
      this.stepNow = step - 1;
      this.form.fileList = [];
    },

    checkboxChangeError(val, type) {
      this.checkError = val;

      if (this.form.checkboxFood == false) {
        if (this.form.conditionsFood.length > 0) {
          this.form.conditionsFood = [];
        }
      } else if (
        this.form.checkboxFood == true &&
        type == "foodSafety" &&
        this.form.conditionsFood.length == 0
      ) {
        this.addCondition("foodSafety");
      }

      if (this.form.checkboxISO == false) {
        if (this.form.conditionsISO.length > 0) {
          this.form.conditionsISO = [];
        }
      } else if (
        this.form.checkboxISO == true &&
        type == "iso" &&
        this.form.conditionsISO.length == 0
      ) {
        this.addCondition("iso");
      }
    },
    async uploadFiles(data) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.setCsrf,
        },
      };

      var formData = new FormData();

      formData.append("_csrf", this.setCsrf);
      if (this.form.files.length > 0) {
        formData.append("certificationId", data._id);
        this.form.files.forEach((e) => {
          formData.append("filesUpload", e.file.raw);
          formData.append("filesName", e.file.name);
        });
      }

      await HTTP.post(`certification/files`, formData, config)
        .then(async (res) => {
          if (res.data.success) {
            return true;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
  },
};
</script>
